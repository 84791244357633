.fullbgBanner {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

.carousel {
    height: 80vh;
    width: 90vw;
    margin: 3vh auto;
    overflow-y: scroll;
    max-width: 710px;
    padding-left: 5px;
}

.swipePanelContainer {
    padding: 0 10px;
    box-sizing: border-box;
}

.swipePanel {
    height: 60vh;
    width: 100%;

    height: 80vh;
    min-height: 300px;
    padding: 20px;
    font-size: 16pt;
    overflow-y: scroll;
    box-sizing: border-box;
}

.controlPanel {
    position: absolute;
    top: 0;
    margin-top: 40vh;
    width: 100vw;
}

.controlPanel > div {
    height: 75px;
    width: 100vw;
    position: relative;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
}

.closeButton {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
}

.nextButton {
    float: right;
}

.controlPanel .MuiButton-root {
    padding: 16px;
    min-width: 0;
    border-radius: 100px;
}

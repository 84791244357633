.recipeContainer {
    position: relative;
    width: 90%;
    margin: 2vh auto 10vh auto;
    padding: 2vw;
    padding-bottom: 5vh;
    max-width: 1200px;
    min-height: 600px;
    border-radius: 40px;
}

.recipeCoverImg {
    background-size: cover;
    background-position: center;
    height: 470px;
    width: 100%;
    border-radius: 30px;
    margin-bottom: 20px;
}

.recipeHeader {
    margin-bottom: 4vh;
}

ul {
    list-style: none;
    padding-left: 10px;
    margin: 0;
}

li {
    margin: 20px 0;
}

.instructionList > li {
    margin-bottom: 30px;
    line-height: 1.5;
}

.ingredientsList {
    columns: 1;
}

.ingredientHeading {
    font-size: 18pt !important;
    font-weight: 500 !important;
    margin-top: 20px !important;
    margin-left: -5px !important;
}

@media screen and (max-width: 959px) {
    .ingredientsList {
        margin-bottom: 40px;
        columns: 2;
    }
}
@media screen and (max-width: 600px) {
    .ingredientsList {
        columns: 1;
        margin-bottom: 40px;
    }
}

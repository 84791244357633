.App {
    min-height: calc(100vh - 100px);
    padding-bottom: 150px;
}
.bgBanner {
    height: 60vh;
    width: 100vw;
    position: absolute;
}
.logo {
    /* font-family: "Urbanist", sans-serif !important; */
    font-family: "Poppins", sans-serif !important;
}

footer {
    background-color: #222;
    padding: 60px 0;
    color: #ffffff;
}

.footerContainer {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
.footerContainer h6 {
    line-height: 1.1;
}

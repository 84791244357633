.dashBanner {
    height: 580px;
    width: 100vw;
    position: relative;
    padding: 0 5%;
    box-sizing: border-box;
    color: white;
    text-align: right;
    padding-bottom: 60px;
    margin-bottom: 20px;
    min-height: 450px;
}

.dashBanner .dashContainer {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.dashBanner .rollingLogo {
    margin-left: 100px;
    padding-top: 30px;
}

.dashBanner .openSourceText {
    text-align: center;
    width: 330px;
    font-size: 14pt;
}
.dashBanner a {
    font-weight: bolder;
}

.dashBanner .coverImg {
    width: 900px;
    margin-top: -260px;
}

@media screen and (max-width: 1300px) {
    .dashBanner .coverImg {
        margin-top: -130px;
        width: 700px;
    }
    .dashBanner .rollingLogo {
        margin-left: 5vw;
        padding-top: 3vw;
    }
}

@media screen and (max-width: 900px) {
    .dashBanner .rollingLogo {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .dashBanner {
        height: calc(60vw + 190px);
    }

    .dashBanner .coverImg {
        margin-top: calc(-8vw + 50px);
        width: calc(100vw - 130px);
        margin-left: auto;
        margin-right: auto;
        display: block;
        min-width: 300px;
    }
}
